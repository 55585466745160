@charset "UTF-8";

@font-face {
  font-family: 'icomoon';
  src:
          url('/static/main/fonts/icomoon.ttf?7ftyjv') format('truetype'),
          url('/static/main/fonts/icomoon.woff?7ftyjv') format('woff'),
          url('/static/main/fonts/icomoon.svg?7ftyjv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gongxiangche:before {
  content: "\e910";
}
.icon-jiantou:before {
  content: "\e900";
}
.icon-youxi:before {
  content: "\e901";
}
.icon-online:before {
  content: "\e902";
}
.icon-offline:before {
  content: "\e903";
}
.icon-canyin:before {
  content: "\e904";
}
.icon-shuma:before {
  content: "\e905";
}
.icon-shangchang:before {
  content: "\e906";
}
.icon-lvxingshe:before {
  content: "\e907";
}
.icon-menkandi:before {
  content: "\e908";
}
.icon-shouxujian:before {
  content: "\e909";
}
.icon-shenhekuai:before {
  content: "\e90a";
}
.icon-fangkuankuai:before {
  content: "\e90b";
}
.icon-dianshang:before {
  content: "\e90c";
}
.icon-O2O:before {
  content: "\e90d";
}
.icon-dianhua:before {
  content: "\e90e";
}
.icon-toubiao:before {
  content: "\e90f";
}
