@charset "UTF-8";

@import "var";
@import "func";

.state {
  .head {
    @include head;
    margin-top: 40px;
  }
  .intro {
    text-align: left;
    padding: 20px 0;
    font-size: 16px;
    font-weight: bold;
    @media screen and (max-width: 700px) {
      font-size: 12px;
      padding: 10px;
    }
    .sub {
      width: 800px;
      margin: 0 auto;
      color: #cccccc;
      font-size: 12px;
      font-weight: normal;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
  }

}