@charset "UTF-8";

@import "var";

@mixin break-line($line: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}

@mixin head() {
  padding: 5px;
  color: $primary-color-dark;
  border-bottom: 2px solid;
  font-size: 24px;
  font-weight: bold;
  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}