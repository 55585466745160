body, ul, li, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
}

ul, li {
  list-style: none;
}

hr {
  border: none;
}

body {
  background: #fdfdfd;
  font-size: 14px;
  line-height: 1.8;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 12px;
  }
}

input, textarea, button {
  background: #ffffff;
  border: none;
  outline: none;
}

.clearfix::after {
  display: table;
  clear: both;
  content: '';
}

textarea {
  resize: none;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.full {
  width: 100%;
}

.flex {
  display: flex;
  align-content: center;
  align-items: center;
}

.flex > .self-start {
  align-self: flex-start;
}

.flex > .self-end {
  align-self: flex-end;
}

.flex > .self-stretch {
  align-self: stretch;
}

.flex .grow {
  flex: 1;
}

.flex.start {
  justify-content: flex-start;
}

.flex.end {
  justify-content: flex-end;
}

.flex.column {
  flex-direction: column;
}

.flex.center {
  justify-content: center;
}

.flex.between {
  justify-content: space-between;
}

.flex.around {
  justify-content: space-around;
}

.flex.align-start {
  align-content: flex-start;
  align-items: flex-start;
}

.flex.align-end {
  align-content: flex-end;
  align-items: flex-end;
}

.flex.align-center {
  align-content: center;
  align-items: center;
}

.flex.align-stretch {
  align-content: stretch;
  align-items: stretch;
}

.flex.align-between {
  align-content: space-between;
}

.flex.align-around {
  align-content: space-around;
}

.icon-menu {
  display: inline-block;
  width: 20px;
  height: 17px;
  border-top: 10px double;
  border-bottom: 3px solid;
  color: #ffffff;
}

.main {
  height: 100vh;
}

.logo {
  width: 200px;
  display: inline-block;
}

@media screen and (max-width: 700px) {
  .logo {
    width: 120px;
  }
}

.header {
  background: #0D739F;
  color: #ffffff;
}

.header .nav > .item {
  box-sizing: content-box;
  margin: 0 40px;
  line-height: 58px;
  width: 70px;
  text-align: center;
  align-self: flex-end;
  position: relative;
}

.header .nav > .item:hover {
  color: #0fffb8;
  border-top: 2px solid;
  cursor: pointer;
}

.header .nav > .item:hover .menu {
  display: block;
}

.header .nav > .item.active {
  color: #0fffb8;
  border-top: 2px solid;
}

.header .nav > .item .menu {
  display: none;
  background: #0F64A2;
  position: absolute;
  top: 42px;
  left: 50%;
  transform: translateX(-50%);
}

.header .nav > .item .menu > .item {
  color: #cccccc;
  text-align: center;
  padding: 5px 10px;
  white-space: nowrap;
}

.header .nav > .item .menu > .item:hover, .header .nav > .item .menu > .item.active {
  color: #ffffff;
}

.top-nav {
  background: #0D739F;
  text-align: center;
  padding: 10px 0;
}

.top-nav .icon {
  position: absolute;
  left: 5px;
  top: 10px;
}

.w {
  width: 1024px;
  margin: 0 auto;
}

@media screen and (max-width: 700px) {
  .w {
    width: 100%;
  }
}

.footer {
  padding: 40px 0;
  background: #34404B;
  color: #ffffff;
  flex-wrap: wrap;
  flex-shrink: 0;
}

@media screen and (max-width: 700px) {
  .footer {
    text-align: center;
    padding: 10px 0;
    font-size: 12px;
  }
}

.footer p {
  line-height: 2;
}

.footer .right {
  margin-left: 40px;
}

@media screen and (max-width: 700px) {
  .footer .right {
    margin: 10px 0 0;
  }
}

.footer .right img {
  width: 200px;
}

@media screen and (max-width: 700px) {
  .footer .right img {
    width: 100px;
  }
}

.divide-line {
  width: 100%;
  height: 2px;
  border-radius: 50%;
  background: linear-gradient(90deg, #0fffb8 0, #188d8c 30%, #188d8c 70%, #0fffb8 100%);
  margin: 20px 0;
}

.only-mobile {
  display: none;
}

@media screen and (max-width: 700px) {
  .only-mobile {
    display: block;
  }
}

.only-pc {
  display: block;
}

@media screen and (max-width: 700px) {
  .only-pc {
    display: none;
  }
}

.sidebar {
  transform: translateX(-100%);
  width: 150px;
  background: #000;
  color: #ccc;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 2;
  padding: 10px;
  box-sizing: border-box;
  transition: all .5s;
}

.sidebar .header {
  background: none;
}

.sidebar.active {
  transform: translateX(0);
}

.sidebar > .item {
  border-bottom: 1px solid #434343;
  display: block;
  padding: 5px;
}

.sidebar > .item > .header {
  font-size: 14px;
}

.sidebar > .item .content {
  padding: 5px;
}

.sidebar > .item .content > .item {
  font-size: 12px;
  text-indent: 1em;
  display: block;
}

.shade {
  display: none;
  position: fixed;
  background: transparent;
  opacity: .5;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  transition: all .5s;
}

.shade.active {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

@font-face {
  font-family: 'icomoon';
  src: url("/static/main/fonts/icomoon.ttf?7ftyjv") format("truetype"), url("/static/main/fonts/icomoon.woff?7ftyjv") format("woff"), url("/static/main/fonts/icomoon.svg?7ftyjv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gongxiangche:before {
  content: "\e910";
}

.icon-jiantou:before {
  content: "\e900";
}

.icon-youxi:before {
  content: "\e901";
}

.icon-online:before {
  content: "\e902";
}

.icon-offline:before {
  content: "\e903";
}

.icon-canyin:before {
  content: "\e904";
}

.icon-shuma:before {
  content: "\e905";
}

.icon-shangchang:before {
  content: "\e906";
}

.icon-lvxingshe:before {
  content: "\e907";
}

.icon-menkandi:before {
  content: "\e908";
}

.icon-shouxujian:before {
  content: "\e909";
}

.icon-shenhekuai:before {
  content: "\e90a";
}

.icon-fangkuankuai:before {
  content: "\e90b";
}

.icon-dianshang:before {
  content: "\e90c";
}

.icon-O2O:before {
  content: "\e90d";
}

.icon-dianhua:before {
  content: "\e90e";
}

.icon-toubiao:before {
  content: "\e90f";
}

.index .banner {
  color: #ffffff;
  background: url("/static/main/img/bg.png") no-repeat center;
  padding: 60px 0;
}

.index .banner .head {
  border-bottom: 2px solid;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
  .index .banner .head {
    font-size: 14px;
  }
}

.index .banner .content {
  width: 700px;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 40px;
}

@media screen and (max-width: 700px) {
  .index .banner .content {
    width: 100%;
    padding: 0 10px;
    font-size: 12px;
  }
}

.index .banner .icons {
  width: 700px;
  text-align: center;
  font-size: 24px;
}

.index .banner .icons .num {
  font-size: 20px;
}

.index .banner .icons i {
  font-size: 50px;
}

@media screen and (max-width: 700px) {
  .index .banner .icons {
    padding: 0 10px;
    width: 100%;
    font-size: 14px;
  }
  .index .banner .icons .num {
    font-size: 14px;
  }
  .index .banner .icons i {
    font-size: 30px;
  }
}

.index .banner .icons .item i {
  font-size: 100px;
}

@media screen and (max-width: 700px) {
  .index .banner .icons .item i {
    font-size: 60px;
  }
}

.index .banner .icons .item p {
  text-align: center;
}

.index .news {
  margin: 40px 0;
}

@media screen and (max-width: 700px) {
  .index .news {
    flex-wrap: wrap;
  }
}

.index .news > .item {
  width: 500px;
  margin: 0 10px;
}

@media screen and (max-width: 700px) {
  .index .news > .item {
    width: 100%;
  }
}

.index .news > .item > .head {
  border-bottom: 2px solid #cccccc;
  color: #188d8c;
  font-size: 20px;
  margin-bottom: 20px;
  padding: 10px 0;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .index .news > .item > .head {
    font-size: 14px;
  }
}

.index .news > .item > .head span {
  position: relative;
}

.index .news > .item > .head span::after {
  content: '';
  position: absolute;
  border-bottom: 2px solid;
  width: 100%;
  left: 0;
  bottom: -12px;
}

.index .news .content {
  color: #000000;
}

.index .news .content > .item {
  margin-bottom: 10px;
}

.index .news .content .img-wrap {
  width: 180px;
  height: 130px;
  overflow: hidden;
}

.index .news .content .img-wrap img {
  width: 100%;
  height: 100%;
}

.index .news .content .intro {
  padding: 20px 10px;
  background: #CEEEEE;
}

.index .news .content .intro .head {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .index .news .content .intro .head {
    font-size: 14px;
  }
}

.index .news .content .intro .m * {
  display: none;
}

.index .news .content .intro .m * img {
  display: none !important;
}

.index .news .content .intro .m *:first-child {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.index .address {
  background: #DEFFFF;
}

.index .address .tab {
  height: 300px;
  margin-right: 70px;
  font-size: 16px;
}

@media screen and (max-width: 700px) {
  .index .address .tab {
    font-size: 12px;
    height: 200px;
    margin-right: 15px;
  }
}

.index .address .tab .item {
  width: 140px;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  background: #1CC2C0;
}

@media screen and (max-width: 700px) {
  .index .address .tab .item {
    width: 100px;
    line-height: 20px;
  }
}

.index .address .tab .item + .item {
  margin-top: 20px;
}

.index .address .content {
  width: 400px;
  height: 400px;
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .index .address .content {
    width: 200px;
    height: 200px;
  }
}

.index .address .content a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 400px;
}

@media screen and (max-width: 700px) {
  .index .address .content a {
    width: 200px;
    height: 200px;
  }
}

.about-us .head {
  padding: 5px;
  color: #188d8c;
  border-bottom: 2px solid;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .about-us .head {
    font-size: 14px;
  }
}

.about-us .intro {
  width: 900px;
  margin: 0 auto;
  padding: 30px 0;
}

@media screen and (max-width: 700px) {
  .about-us .intro {
    width: 100%;
  }
}

.about-us .intro p {
  margin: 20px 0;
  line-height: 1.7;
  letter-spacing: 2px;
  font-size: 16px;
}

.about-us .map {
  width: 900px;
  margin: 0 auto;
  padding: 40px 0;
}

@media screen and (max-width: 700px) {
  .about-us .map {
    width: 100%;
  }
}

.about-us .map > .head {
  margin-bottom: 40px;
}

@media screen and (max-width: 700px) {
  .about-us .map > .item {
    padding: 0 10px;
  }
}

.about-us .map > .item .msg b {
  display: inline-block;
  font-size: 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: 700px) {
  .about-us .map > .item .msg b {
    font-size: 14px;
  }
}

.about-us .map > .item .img-wrap {
  width: 380px;
  margin-left: 150px;
  flex-shrink: 0;
}

@media screen and (max-width: 700px) {
  .about-us .map > .item .img-wrap {
    margin-left: 0;
    width: 100px;
  }
}

.news .tab {
  margin: 30px 0;
}

.news .tab > .item {
  cursor: pointer;
  padding: 10px;
  background: #859B9B;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
}

.news .tab > .item.active {
  background: #1BC2C0;
}

@media screen and (max-width: 700px) {
  .news .tab > .item {
    font-size: 12px;
  }
}

.news .tab-content {
  display: none;
}

.news .tab-content.active {
  display: block;
}

.news .content {
  flex-wrap: wrap;
}

@media screen and (max-width: 700px) {
  .news .content {
    justify-content: center;
  }
}

.news .content .item {
  border: 1px solid;
  flex-basis: 300px;
  margin: 10px 20px;
}

.news .content .item .intro {
  padding: 10px;
}

.news .content .item .head {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 16px;
  margin-bottom: 5px;
}

.news .pagination {
  margin: 40px 0;
}

.news .pagination .item {
  border: 1px solid #cccccc;
  width: 30px;
  line-height: 30px;
  text-align: center;
}

.news .pagination .item.active {
  background: #ccc;
  cursor: not-allowed;
}

.news .pagination .item.disable {
  cursor: not-allowed;
  background: rgba(195, 193, 193, 0.3);
}

.news .detail {
  margin: 30px auto;
}

.news .detail .tab {
  margin-top: 44px;
  margin-bottom: 10px;
  text-align: center;
}

.news .detail .tab .item {
  font-size: 14px;
}

.news .detail > .content {
  width: 70%;
}

.news .detail > .part {
  width: 25%;
}

.news .detail > .part .tab-content .item {
  border-bottom: 1px solid;
  padding: 5px 0;
}

.news .detail > .part .tab-content .item > .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-right: 9px;
}

.news .detail .news-header {
  font-size: 24px;
}

.news .detail .news-header .h {
  padding-bottom: 10px;
  border-bottom: 2px solid #cccccc;
}

.news .detail .news-header > .extra {
  margin-top: 10px;
  font-size: 14px;
}

.news .detail .news-header > .extra .time {
  color: #aeaeae;
}

.news .detail .news-body {
  margin-top: 20px;
}

.state .head {
  padding: 5px;
  color: #188d8c;
  border-bottom: 2px solid;
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
}

@media screen and (max-width: 700px) {
  .state .head {
    font-size: 14px;
  }
}

.state .intro {
  text-align: left;
  padding: 20px 0;
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .state .intro {
    font-size: 12px;
    padding: 10px;
  }
}

.state .intro .sub {
  width: 800px;
  margin: 0 auto;
  color: #cccccc;
  font-size: 12px;
  font-weight: normal;
}

@media screen and (max-width: 700px) {
  .state .intro .sub {
    width: 100%;
  }
}

.advantage .tech {
  padding: 40px;
  background: url("/static/main/img/advantage/bg.jpg") no-repeat center center;
}

@media screen and (max-width: 700px) {
  .advantage .tech {
    background-size: auto 100%;
  }
}

.advantage .tech .head {
  padding: 5px;
  color: #188d8c;
  border-bottom: 2px solid;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
  .advantage .tech .head {
    font-size: 14px;
  }
}

.advantage .tech .intro {
  width: 1000px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
}

@media screen and (max-width: 700px) {
  .advantage .tech .intro {
    width: 100%;
  }
}

.advantage .tech .list {
  width: 1000px;
}

.advantage .tech .list > .item + .item {
  margin-left: 10px;
}

@media screen and (max-width: 700px) {
  .advantage .tech .list {
    width: 100%;
    flex-wrap: wrap;
  }
  .advantage .tech .list > .item + .item {
    margin: 10px 0 0;
  }
}

.advantage .banner4 {
  background: url("/static/main/img/advantage/banner4.jpg") center bottom/100% auto no-repeat;
  padding: 40px 0 200px;
}

@media screen and (max-width: 700px) {
  .advantage .banner4 {
    padding: 20px 0;
  }
}

.advantage .banner4 .head {
  padding: 5px;
  color: #188d8c;
  border-bottom: 2px solid;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .advantage .banner4 .head {
    font-size: 14px;
  }
}

.advantage .banner4 .content {
  margin-top: 30px;
  width: 1000px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .advantage .banner4 .content {
    width: 100%;
    margin-top: 0;
    padding: 10px;
    font-size: 12px;
  }
}
