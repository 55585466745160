@charset "UTF-8";

@import "var";
@import "func";

body, ul, li, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
}

ul, li {
  list-style: none;
}

hr {
  border: none;
}

body {
  background: #fdfdfd;
  font-size: 14px;
  line-height: 1.8;
  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
}

input, textarea, button {
  background: #ffffff;
  border: none;
  outline: none;
}

.clearfix::after {
  display: table;
  clear: both;
  content: '';
}

textarea {
  resize: none;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.full {
  width: 100%;
}

.flex {
  display: flex;
  align-content: center;
  align-items: center;
}

.flex > .self-start {
  align-self: flex-start;
}

.flex > .self-end {
  align-self: flex-end;
}

.flex > .self-stretch {
  align-self: stretch;
}

.flex .grow {
  flex: 1;
}

.flex.start {
  justify-content: flex-start;
}

.flex.end {
  justify-content: flex-end;
}

.flex.column {
  flex-direction: column;
}

.flex.center {
  justify-content: center;
}

.flex.between {
  justify-content: space-between;
}

.flex.around {
  justify-content: space-around;
}

.flex.align-start {
  align-content: flex-start;
  align-items: flex-start;
}

.flex.align-end {
  align-content: flex-end;
  align-items: flex-end;
}

.flex.align-center {
  align-content: center;
  align-items: center;
}

.flex.align-stretch {
  align-content: stretch;
  align-items: stretch;
}

.flex.align-between {
  align-content: space-between;
}

.flex.align-around {
  align-content: space-around;
}

.icon-menu {
  display: inline-block;
  width: 20px;
  height: 17px;
  border-top: 10px double;
  border-bottom: 3px solid;
  color: #ffffff;
}

.main {
  height: 100vh;
}

.logo {
  width: 200px;
  display: inline-block;
  @media screen and(max-width: 700px) {
    width: 120px;
  }
}

.header {
  background: #0D739F;
  color: #ffffff;
  .nav {
    & > .item {
      box-sizing: content-box;
      margin: 0 40px;
      line-height: 58px;
      width: 70px;
      text-align: center;
      align-self: flex-end;
      position: relative;
      &:hover {
        color: $primary-color;
        border-top: 2px solid;
        cursor: pointer;
        .menu {
          display: block;
        }
      }
      &.active {
        color: $primary-color;
        border-top: 2px solid;
      }
      .menu {
        display: none;
        background: #0F64A2;
        position: absolute;
        top: 42px;
        left: 50%;
        transform: translateX(-50%);
        & > .item {
          color: #cccccc;
          text-align: center;
          padding: 5px 10px;
          white-space: nowrap;
          &:hover, &.active {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.top-nav {
  background: #0D739F;
  text-align: center;
  padding: 10px 0;
  .icon {
    position: absolute;
    left: 5px;
    top: 10px;
  }
}

.w {
  width: 1024px;
  margin: 0 auto;
  @media screen and(max-width: 700px) {
    width: 100%;
  }
}

.footer {
  padding: 40px 0;
  background: #34404B;
  color: #ffffff;
  flex-wrap: wrap;
  flex-shrink: 0;
  @media screen and (max-width: 700px) {
    text-align: center;
    padding: 10px 0;
    font-size: 12px;
  }
  p {
    line-height: 2;
  }
  .right {
    margin-left: 40px;
    @media screen and (max-width: 700px) {
      margin: 10px 0 0;
    }
    img {
      width: 200px;
      @media screen and (max-width: 700px) {
        width: 100px;
      }
    }
  }
}

.divide-line {
  width: 100%;
  height: 2px;
  border-radius: 50%;
  background: linear-gradient(90deg, $primary-color 0, $primary-color-dark 30%, $primary-color-dark 70%, $primary-color 100%);
  margin: 20px 0;
}

.only-mobile {
  display: none;
  @media screen and (max-width: 700px) {
    display: block;
  }
}

.only-pc {
  display: block;
  @media screen and (max-width: 700px) {
    display: none;
  }
}

// sidebar
.sidebar {
  transform: translateX(-100%);
  width: 150px;
  background: #000;
  color: #ccc;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 2;
  padding: 10px;
  box-sizing: border-box;
  transition: all .5s;
  .header {
    background: none;
  }
}

.sidebar.active {
  transform: translateX(0);
}

.sidebar > .item {
  border-bottom: 1px solid #434343;
  display: block;
  padding: 5px;
}

.sidebar > .item > .header {
  font-size: 14px;
}

.sidebar > .item .content {
  padding: 5px;
}

.sidebar > .item .content > .item {
  font-size: 12px;
  text-indent: 1em;
  display: block;
}

.shade {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0);
  opacity: .5;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  transition: all .5s;
}

.shade.active {
  display: block;
  background: rgba(0, 0, 0, .6);
}