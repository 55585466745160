@charset "UTF-8";


@import "var";
@import "func";

.advantage {
  .tech {
    padding: 40px;
    background: url("/static/main/img/advantage/bg.jpg") no-repeat center center;
    @media screen and(max-width: 700px) {
      background-size: auto 100%;
    }
    .head {
      @include head;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .intro {
      width: 1000px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 40px;
      @media screen and(max-width: 700px) {
        width: 100%;
      }
    }
    .list {
      width: 1000px;
      & > .item + .item {
        margin-left: 10px;
      }
      @media screen and(max-width: 700px) {
        width: 100%;
        flex-wrap: wrap;
        & > .item + .item {
          margin: 10px 0 0;
        }
      }
    }
  }
  .banner4 {
    background: url('/static/main/img/advantage/banner4.jpg') center bottom/100% auto no-repeat;
    padding: 40px 0 200px;
    @media screen and(max-width: 700px) {
      padding: 20px 0;
    }
    .head {
      @include head;
    }
    .content {
      margin-top: 30px;
      width: 1000px;
      text-align: center;
      @media screen and(max-width: 700px) {
        width: 100%;
        margin-top: 0;
        padding: 10px;
        font-size: 12px;
      }
    }
  }
}
