@charset "UTF-8";

@import "func";
@import "var";

.news {
  .tab {
    margin: 30px 0;
  }
  .tab > .item {
    cursor: pointer;
    padding: 10px;
    background: #859B9B;
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    &.active {
      background: #1BC2C0;
    }
    @media screen and (max-width: 700px) {
      font-size: 12px;
    }
  }
  .tab-content {
    display: none;
    &.active {
      display: block;
    }
  }
  .content {
    flex-wrap: wrap;
    @media screen and (max-width: 700px) {
      justify-content: center;
    }
    .item {
      border: 1px solid;
      flex-basis: 300px;
      margin: 10px 20px;
      .intro {
        padding: 10px;
      }
      .head {
        @include break-line(1);
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
  }
  .pagination {
    margin: 40px 0;
    .item {
      border: 1px solid #cccccc;
      width: 30px;
      line-height: 30px;
      text-align: center;
      &.active {
        background: #ccc;
        cursor: not-allowed;
      }
      &.disable {
        cursor: not-allowed;
        background: rgba(195, 193, 193, .3);
      }
    }
  }
  .detail {
    margin: 30px auto;
    .tab {
      margin-top: 44px;
      margin-bottom: 10px;
      text-align: center;
    }
    .tab .item{
      font-size: 14px;
    }
    & > .content {
      width: 70%;
    }
    & > .part {
      width: 25%;
      .tab-content .item {
        border-bottom: 1px solid;
        padding: 5px 0;
        & > .title {
          @include break-line(1);
          margin-right: 9px;
        }
      }
    }
    .news-header {
      font-size: 24px;
      .h {
        padding-bottom: 10px;
        border-bottom: 2px solid #cccccc;
      }
      & > .extra{
        margin-top: 10px;
        font-size: 14px;
        .time {
          color: #aeaeae;
        }
      }
    }
    .news-body {
      margin-top: 20px;
    }
  }
}
