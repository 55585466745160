@charset "UTF-8";
@import "func";
@import "var";

.about-us {
  .head {
    @include head;
  }
  .intro {
    width: 900px;
    margin: 0 auto;
    padding: 30px 0;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
    p {
      margin: 20px 0;
      line-height: 1.7;
      letter-spacing: 2px;
      font-size: 16px;
    }
  }
  .map {
    width: 900px;
    margin: 0 auto;
    padding: 40px 0;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
    & > .head {
      margin-bottom: 40px;
    }
    & > .item {
      @media screen and (max-width: 700px) {
        padding: 0 10px;
      }
      .msg {
        b {
          display: inline-block;
          font-size: 20px;
          margin-bottom: 10px;
          @media screen and (max-width: 700px) {
            font-size: 14px;
          }
        }
      }
      .img-wrap {
        width: 380px;
        margin-left: 150px;
        flex-shrink: 0;
        @media screen and (max-width: 700px) {
          margin-left: 0;
          width: 100px;
        }
      }
    }
  }
}