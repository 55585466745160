@charset "UTF-8";

@import "var";
@import "func";

.index {
  .banner {
    color: #ffffff;
    background: url("/static/main/img/bg.png") no-repeat center;
    padding: 60px 0;
    .head {
      border-bottom: 2px solid;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      @media screen and (max-width: 700px){
        font-size: 14px;
      }
    }
    .content {
      width: 700px;
      font-size: 16px;
      line-height: 1.5;
      color: #fff;
      margin-bottom: 40px;
      @media screen and (max-width: 700px) {
        width: 100%;
        padding: 0 10px;
        font-size: 12px;
      }
    }
    .icons {
      width: 700px;
      text-align: center;
      font-size: 24px;
      .num {
        font-size: 20px;
      }
      i {
        font-size: 50px;
      }
      @media screen and (max-width: 700px) {
        padding: 0 10px;
        width: 100%;
        font-size: 14px;
        .num {
          font-size: 14px;
        }
        i {
          font-size: 30px;
        }
      }
      .item {
        i {
          font-size: 100px;
          @media screen and (max-width: 700px) {
            font-size: 60px;
          }
        }
        p {
          text-align: center;
        }
      }
    }
  }
  .news {
    margin: 40px 0;
    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }
    & > .item {
      width: 500px;
      margin: 0 10px;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
      & > .head {
        border-bottom: 2px solid #cccccc;
        color: $primary-color-dark;
        font-size: 20px;
        margin-bottom: 20px;
        padding: 10px 0;
        font-weight: bold;
        @media screen and (max-width: 700px) {
          font-size: 14px;
        }
        span {
          position: relative;
        }
        span::after {
          content: '';
          position: absolute;
          border-bottom: 2px solid;
          width: 100%;
          left: 0;
          bottom: -12px;
        }
      }
    }
    .content {
      color: #000000;
      & > .item {
        margin-bottom: 10px;
      }
      .img-wrap {
        width: 180px;
        height: 130px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .intro {
        padding: 20px 10px;
        background: #CEEEEE;
        .head {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 20px;
          @include break-line(1);
          @media screen and (max-width: 700px) {
            font-size: 14px;
          }
        }
        .m * {
          img {
            display: none!important;
          }
          display: none;
          &:first-child {
            display: block;
            @include break-line(3);
          }
        }
      }
    }
  }
  .address {
    background: #DEFFFF;
    .tab {
      height: 300px;
      margin-right: 70px;
      font-size: 16px;
      @media screen and (max-width: 700px) {
        font-size: 12px;
        height: 200px;
        margin-right: 15px;
      }
      .item {
        width: 140px;
        text-align: center;
        color: #ffffff;
        line-height: 40px;
        background: #1CC2C0;
        @media screen and (max-width: 700px) {
          width: 100px;
          line-height: 20px;
        }
        & + .item {
          margin-top: 20px;
        }
      }
    }
    .content {
      width: 400px;
      height: 400px;
      overflow: hidden;
      @media screen and (max-width: 700px) {
        width: 200px;
        height: 200px;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;
        width: 400px;
        @media screen and (max-width: 700px) {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}